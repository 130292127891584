<template>
  <div>
    <b-card no-body class="mb-4 p-3">
      <div class="mb-3">
        <b-button variant="primary" v-if="permissao.criar" @click="abrirCadastrar">{{
            $t("COMUNICADO.CRIAR_COMUNICADO")
        }}</b-button>
      </div>

      <div class="tabela-usuarios">
        <b-table head-variant="light" hover :items="items" :fields="campos">
          <template v-slot:cell(acoes)="item">
            <div class="flex">
              <b-dropdown variant="outline-default">
                <b-dropdown-item v-if="permissao.editar" @click="editar(item.item.id)">
                  {{$t("GERAL.EDITAR")}}
                </b-dropdown-item>
                <b-dropdown-item v-if="permissao.deletar" @click="excluir(item.item)">
                  {{ $t("GERAL.EXCLUIR") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
    <div class="header-table-operacoes">
      <b-form-group label="Por Pagina" label-for="per-page-select" label-class="fw-medium" class="por-pagina">
        <b-form-select id="per-page-select" v-model="perPage" @change="setPorPagina" :options="pageOptions">
        </b-form-select>
      </b-form-group>
      <b-pagination @change="setPage" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
    </div>
  </div>
</template>

<script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import ComunicadoService from "@/common/services/comunicado/comunicado.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "@/common/utils/helpers";
import Confirmacao from "@/components/confirmacao/Confirmacao";

export default {
  name: "Comunicado",
  components: {

  },
  data() {
    return {
      campos: [
        {
          key: "titulo",
          label: this.$t("COMUNICADO.NOME"),
          sortable: true,
        },
        {
          key: "dataInicio",
          label: this.$t("COMUNICADO.DE"),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
        },
        {
          key: "dataExpiracao",
          label: this.$t("COMUNICADO.ATE"),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
        },
        {
          key: "status",
          label: this.$t("COMUNICADO.STATUS"),
          sortable: true,
        },
        {
          key: "acoes",
          label: this.$t("GERAL.ACOES"),
        },
      ],
      items: [
      ],
      form: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t("GERAL.PAGINACAO") },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: this.$t("MENU.COMUNICADO") }]);
    this.listar();
  },
  methods: {
    abrirCadastrar() {
      this.$router.push({ name: "criar-comunicado" });
    },
    editar(id) {
      this.$router.push({ name: "editar-comunicado", params: { id } });
    },
    setPage(e) {
      this.listar(e);
    },
    setPorPagina(e) {
      this.listar(undefined, e);
    },
    excluir(item) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("COMUNICADO.CONFIRMAR_EXCLUIR").formatUnicorn({
          nome: item.titulo,
        })
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          ComunicadoService.excluir(item.id)
            .then(() => {
              this.$router.go();
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    listar(paginaAtual, porPagina) {
      this.$store.dispatch(START_LOADING);

      this.form.TamanhoDaPagina = porPagina || this.perPage;
      this.form.NumeroDaPagina = (paginaAtual || this.currentPage) - 1;

      ComunicadoService.listar(this.form)
        .then((result) => {
          result.data.data.itens.sort((a, b) =>
            a.dataInicio.localeCompare(b.dataInicio)
          );
          this.items = result.data.data.itens;
          

          this.perPage = porPagina || this.perPage;
          this.currentPage = paginaAtual || this.currentPage;
          this.totalRows = result.data.data.paginacao.totalDeElementos;
        })
        .catch(() => { })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    
  },
  computed : {
    permissao : function(){
      return helpers.validarAcesso("Comunicado")
    }
  }
};
</script>

<style>
</style>
