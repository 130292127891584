import ApiService from "@/common/api/api.service";

const ComunicadoService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));

    return str.join("&");
  },
  criar(formData) {
    return ApiService.post(
      `/Comunicado`,
      {},
      undefined,
      undefined,
      undefined,
      formData
    );
  },
  editar(formData) {
    return ApiService.put("/Comunicado", formData);
  },
  exibicao() {
    return ApiService.get(`/Comunicado/exibicao`, "", { noToast: true });
  },
  excluir(id) {
    return ApiService.delete(`/Comunicado/${id}`, "");
  },
  buscarComunicado(id) {
    return ApiService.get(`/Comunicado/${id}`, "", { noToast: true });
  },
  listar(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`Comunicado?${sendForm}`);
  },
  listarCnpj(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`Comunicado/cnpjs/${form.id}?${sendForm}`);
  },
};

export default ComunicadoService;
